<template>
<div>
    <v-row>
        <v-col cols="12" md="6" sm="6">
            <v-card class="mx-auto rounded-lg" min-height="300" flat>
                <Calendario :fecha="FechaHoy"/>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" sm="6">
            <v-card class="rounded-lg " flat>
                <Turno :fecha="FechaHoy" vista="calendario"/>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from "dayjs"
export default {
    components: {
        Calendario: ()=> import('@/components/Calendario'),
        Turno: ()=> import('@/components/Turno'),
    },
    name: 'Usuario',
    data: () => ({
        fecha: dayjs().format('YYYY-MM-DD')
    }),
    computed: {
        ...mapState(['dialog','update']),
        FechaHoy(){
            return this.fecha
        }   ,
        UpdateComponente() {
            return this.update.turno
        },
    },
    mounted(){
        window.scrollTo(0, 0)
    },
    watch: {
        UpdateComponente: function (val) {
            if (val && this.fecha !== val) {
                this.fecha = dayjs().format('YYYY-MM-DD')
            }
        },
    }
}
</script>

<style>

</style>
